<template>
       <div class="container">
      <div class="col text-center tracking-in-contract-bck">
        <p class="p-msg">" A <span> cobrança</span> não é sobre <span> números</span>, é sobre <span> conexões</span>! "</p>
        <p class="p-bottom">Desenvolvido por D&R {{ date() }}.</p>
      </div>
    </div>
</template>

<script>
export default {
    name:"RodapeMenu",
    methods:{
        date(){
            let date = new Date();
            let dateY = date.getFullYear();
            return dateY;
        }
    }
    
}
</script>

<style scoped>
.p-msg{
  font-size: 16px;
  font-style: italic;
  text-transform: uppercase;
  font-weight: bold;
}

.p-msg span{
    color:  #f54308;
}
.p-bottom{
  font-size: 12px;
  color: rgba(185, 167, 167, 0.467);
}
.tracking-in-contract-bck {
  -webkit-animation: tracking-in-contract-bck 3s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: tracking-in-contract-bck 3s cubic-bezier(0.215, 0.61, 0.355, 1)
    both;
}
.div-row {
  display: flex;
  flex-direction: row;
}

@-webkit-keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
    transform: translateZ(400px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
    transform: translateZ(400px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
</style>