<template >
  <div class="main-div">
    <Menu />
    <RodapeMenu/>
    
  </div>
</template>

<script>
import Menu from "../components/menu/menu.vue";
import RodapeMenu from "../components/menu/rodapeMenu.vue"
export default {
  components: {
    Menu,
    RodapeMenu
  },
};
</script>
<style scoped>
.main-div{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

</style>