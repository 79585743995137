<template>
  <div class=" menu">
    <TopMenu />

    <div
      v-show="showMenu"
      id="menu-options"
      class="row menu-options slide-in-fwd-tr text-center"
    >
      <p class="tracking-in-contract-bck" @click="sobre">Sobre</p>
      <p class="tracking-in-contract-bck" @click="trabalhos">Nossos Trabalhos</p>
       <p class="tracking-in-contract-bck" @click="imprensa">Imprensa</p>
      <p class="tracking-in-contract-bck" @click="nossosClientes">Nossos Clientes</p>
        <p class="tracking-in-contract-bck" @click="contato">Contato</p>
        <p class="tracking-in-contract-bck" @click="trabalheConosco">Trabalhe conosco</p>
    </div>
  </div>
</template>

<script>
import TopMenu from "./topMenu.vue";
export default {
  name: "Menu",
  components: {
    TopMenu,
  },
  data() {
    return {
      showMenu: true,
    };
  },
  methods: {
    sobre() {
      this.$router.push({
        name: "Sobre",
      });
    },
    contato() {
      this.$router.push({
        name: "Contato",
      });
    },
      nossosClientes() {
      this.$router.push({
        name: "NossosClientes",
      });
    },
    trabalhos() {
      this.$router.push({
        name: "Trabalhos",
      });
    },
    imprensa() {
      this.$router.push({
        name: "Imprensa",
      });
    }, 
    trabalheConosco() {
      this.$router.push({
        name: "TrabalheConosco",
      });
    },
  },
};
</script>


<style scoped>

.menu-options p:hover{
  border-bottom: none !important;
}

@media (max-width: 767.98px) {
  .menu-options {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.tracking-in-contract-bck {
  -webkit-animation: tracking-in-contract-bck 3s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: tracking-in-contract-bck 3s cubic-bezier(0.215, 0.61, 0.355, 1)
    both;
}

.menu-options {
  margin-top: calc(50vh - 160px);
  width: auto;
  height: auto;
  display: flex;
  justify-content: space-evenly;
}
.menu-options p {
  font-size: 26px;
  transition: all ease-out 0.2s;
}
.menu-options p:hover {
  border-bottom: solid 1px;
  cursor: pointer;
  font-weight: 700;
  transition: all ease-out 0.2s;
}
.text {
  display: flex;
  flex-direction: row;
  justify-content: end;
  opacity: 1;
}
.text:hover {
  cursor: pointer;
}
.text .span-menu {
  margin-right: 10px;
  opacity: 0.3;
  transition: all ease-out 0.5s;
}

.text:hover .span-menu {
  opacity: 1;
  transition: all ease-out 0.5s;
}
.img {
  width: 130px;
  height: auto;
  cursor: pointer;
  /* filter: brightness(0%) invert(100%); */
}

.slide-in-fwd-tr {
  -webkit-animation: slide-in-fwd-tr 1s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-fwd-tr 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-fwd-tr {
  0% {
    -webkit-transform: translateZ(-1400px) translateY(-800px) translateX(1000px);
    transform: translateZ(-1400px) translateY(-800px) translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
    transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-tr {
  0% {
    -webkit-transform: translateZ(-1400px) translateY(-800px) translateX(1000px);
    transform: translateZ(-1400px) translateY(-800px) translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
    transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
    transform: translateZ(400px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
    transform: translateZ(400px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
</style>